import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-footer',
  templateUrl: './gym-footer.component.html',
  styleUrls: ['./gym-footer.component.scss']
})
export class GymFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openStarthereCollection(value){
    let uuid = this.getUniqueId(2);
    window.open("https://starthere.at/digil/" + uuid + '?collection='+value, "_blank")
  }

  getUniqueId(parts) {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('');
  }

}
