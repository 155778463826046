<section class="app1 screenshot" id="screenshot">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 m-b-10">
                <div class="title title1">
                    <div class="format-small-text">
                        <h6 class="font-primary text-black"><span>Unsere Bits und Bytes</span></h6>
                    </div>
                    <br>
                    <div class="main-title">
                        <h2 class="text-black text-image2">{{currentSlide.name}}</h2>
                    </div>
                    <div class="sub-black">
                        <p class="text-black para">
                            {{currentSlide.description}}
                            <br>
                            <a (click)="openURL()" target='_blank'>{{currentSlide.url}}</a>
                        </p>
                    </div>
                 
                </div>
            </div>
        </div>
        <div class="row set-relative">
            <div class="col-12">
                <!-- Swiper -->
                <swiper [effect]="'cards'" [grabCursor]="true" [config]="config" class="mySwiper"
                    (slideChange)="updateActiveIndex($event)">
                    <ng-template swiperSlide *ngFor="let slide of slides; index as i">
                        <img alt="" [src]="slide.img" class="img-fluid">
                    </ng-template>
                </swiper>
            </div>
            <div class="set-abs screen-container">
                <img src="assets/images/gym/portfolio/mockup-1.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>
<!-- screenshot section end-->