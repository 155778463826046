import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-header',
  templateUrl: './gym-header.component.html',
  styleUrls: ['./gym-header.component.scss']
})
export class GymHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  headercarousel = [
    { 
      tagLine1:"Animate Ideas.",
      tagLine2:" Go Agile.",
      tagline3:" Be Digital.",
      description:"Stell dir vor, deine Idee ist eine Party-Einladung, aber du steckst in den Vorbereitungen fest. Keine Sorge, wir sind dein Party-Planer! Bei digil.me verwandeln wir Ideenchaos in klare Konzepte und kickstarten deine digitale Revolution. Auf geht's, vom Brainstorming zur Launchparty!"    
    },
    { 
      tagLine1:"Go Agile.",
      tagLine2:" Go Agile.",
      tagline3:" Be Digital.",
      description:"Vergiss starre Pläne und endlose Meetings. Wir bringen Flexibilität in deine Projekt-Playlist! Entdecke, wie agiles Arbeiten deinen Ideen-Rhythmus findet und sie in schnelle Schritte vom Konzept zum Prototyp verwandelt. Wir sind die Choreografen deiner digitalen Performance."    
    },
    { 
      tagLine1:"Be Digital.",
      tagLine2:" Go Agile.",
      tagline3:" Be Digital.",
      description:"Jetzt, wo deine Idee den Beat vorgibt, lass sie nicht an der Tür stehen. Wir sorgen dafür, dass sie der Star der Show wird – von der ersten Skizze bis zum marktreifen Produkt. Zusammen machen wir aus 'Was, wenn' ein 'Schau her!' und erobern die digitale Bühne."    
    },  
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: false,
    nav: false,
    autoplay: false,
    autoplayTimeout:10000, 
    slideSpeed: 10000,
    navSpeed: 10000,
    paginationSpeed: 10000,
    loop: true,
  }
}
