<!-- about section Start-->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/logo/logo_digil.png" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before "><span>Start with the why!</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text text-image2">Warum Digil.me?</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">Unsere Mission ist es, aus rohen Ideen digitale Erfolge zu formen. Wir spezialisieren uns auf die Entwicklung von MVPs, bieten agiles Projektmanagement und kreieren maßgeschneiderte Software, die echte Herausforderungen meistert. Inspiriert von persönlichen Erfahrungen in der Tech-Welt, streben wir danach, einen Raum zu schaffen, in dem Innovation mehr als nur ein Schlagwort ist – sie ist unsere gelebte Praxis.</p>
                            <p class="p-light about-para">Unser Warum ist es, die Menschheit durch die digitale Revolution zu führen – nicht als passive Beobachter, sondern als aktive Gestalter unserer digitalen Zukunft. Bei digil.me geht es darum, die Macht der Technologie zu entmystifizieren und sie in etwas zu verwandeln, das Energie gibt, statt zu nehmen. Animate Ideas. Go Agile. Be Digital. Wir sind hier, um dir zu zeigen, dass du nicht nur im digitalen Zeitalter überleben, sondern es nach deinen Regeln gestalten kannst.</p>
                            <p class="p-light about-para">Bei digil.me dreht sich alles um Dynamik, Klarheit und Agilität. Unsere Philosophie spiegelt sich in der Vielfalt und Anpassungsfähigkeit wider, die wir durch unsere farbenfrohe und formenreiche Gestaltung zum Ausdruck bringen. Unser Ansatz ist es, agil und iterativ zu arbeiten, um sicherzustellen, dass wir immer in Bewegung bleiben und Lösungen finden, die uns und unsere Kunden voranbringen. </p>
                        </div>
                        <!-- <a href="javascript:void(0)" class="btn btn-default primary-btn">read more</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
