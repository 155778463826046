import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-brand',
  templateUrl: './gym-brand.component.html',
  styleUrls: ['./gym-brand.component.scss']
})
export class GymBrandComponent implements OnInit {

  hoveredService: string = '';

  constructor() { }

  ngOnInit() {
  }
  brands = [
    {
      img: "assets/images/gym/brand/1.png",
      url: "https://elevatex.de/"
    },
    {
      img: "assets/images/gym/brand/2.png",
      url: "https://www.coworkgroup.de/"
    },
    {
      img: "assets/images/gym/brand/3.png",
      url: "https://www.starthere.at/home"
    },
    {
      img: "assets/images/gym/brand/4.png",
      url: "https://openinnovationcircle.com/"
    },
    {
      img: "assets/images/gym/brand/5.png",
      url: "https://www.marliesmittler.de/"
    },
    {
      img: "assets/images/gym/brand/6.png",
      url: "https://www.xi-consulting.de/"
    }
  ]

  services = [
    {
      "name": "MVP Erstellung",
      "description": "Aus Ideen werden Taten – und aus Taten werden Produkte. Wir zimmern dir ein MVP, so stabil wie ein schwedisches Möbelstück, nur schneller aufgebaut.",
      "imageURL": "https://example.com/images/mvp-erstellung.jpg"
    },
    {
      "name": "CTO as a Service",
      "description": "Deine Technikvision braucht einen Käpt’n? Hol dir einen CTO an Bord, der mehr steuert als nur deine IT.",
      "imageURL": "https://example.com/images/cto-service.jpg"
    },
    {
      "name": "Projektmanagement",
      "description": "Projekte managen wir nicht, wir jonglieren sie – mit einer Hand gebunden und einem Auge aufs Ziel.",
      "imageURL": "https://example.com/images/projektmanagement.jpg"
    },
    {
      "name": "Freelancers",
      "description": "Unsere Freelancer sind wie digitale Superhelden – nur ohne Umhang, dafür mit Laptop.",
      "imageURL": "https://example.com/images/freelancers.jpg"
    },
    {
      "name": "New Work",
      "description": "Büro? Das war gestern. Wir entfesseln deine Arbeit, wo auch immer du bist – flexibel, digital, revolutionär.",
      "imageURL": "https://example.com/images/new-work.jpg"
    },
    {
      "name": "Prozessoptimierung",
      "description": "Deine Prozesse sind so 90er? Wir pimpen sie auf – schlank, smart und sexy.",
      "imageURL": "https://example.com/images/prozessoptimierung.jpg"
    },
    {
      "name": "Business as a Service",
      "description": "Dein Business soll laufen wie eine gut geölte App. Wir liefern dir Service, der tickt, klickt und passt.",
      "imageURL": "https://example.com/images/business-service.jpg"
    },
    {
      "name": "Ideation",
      "description": "Ideen sind wild, wir sind ihr Dompteur – gemeinsam bringen wir den Zirkus zum Laufen.",
      "imageURL": "https://example.com/images/ideation.jpg"
    },
    {
      "name": "Validation",
      "description": "Ist deine Idee ein Volltreffer oder ein Schuss ins Blaue? Wir checken das, mit Daten, die nicht lügen.",
      "imageURL": "https://example.com/images/validation.jpg"
    },
    {
      "name": "Räume digital/physisch",
      "description": "Von virtuellen Meetings bis zum realen Handshake – wir gestalten Räume, die zusammenbringen.",
      "imageURL": "https://example.com/images/raeume.jpg"
    },
    {
      "name": "Kollaboration",
      "description": "Teamwork on Steroids – Kollaboration, die die Funken fliegen lässt.",
      "imageURL": "https://example.com/images/kollaboration.jpg"
    },
    {
      "name": "Geschäftsmodellentwicklung",
      "description": "Wir schnitzen an deinem Geschäftsmodell, bis es passt – wie ein maßgeschneiderter Anzug, nur für dein Business.",
      "imageURL": "https://example.com/images/geschaeftsmodellentwicklung.jpg"
    },
    {
      "name": "Individualsoftware",
      "description": "Stangenware war gestern. Hier gibt’s Software so individuell wie dein Fingerabdruck.",
      "imageURL": "https://example.com/images/individualsoftware.jpg"
    }
  ]




  brandcarouselOptions = {
    items: 6,
    margin: 50,
    autoHeight: true,
    nav: false,
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 2,
        margin: 10
      },
      320: {
        items: 3,
        margin: 10
      },
      480: {
        items: 4,
        margin: 30
      },
      768: {
        items: 5,
        margin: 50
      },
      1200: {
        items: 6,
        margin: 50
      }
    }
  }

  openURL(brand){
    window.open(brand.url, '_blank')
}


}
