<!--footer start-->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">About Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/digil_logo_trans_99px.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Digil.me</h6>
                            <h6 class="text-white para-address">Überm Neckar 21/2, </h6>
                            <h6 class="text-white para-address">72768 Reutlingen.</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Kontakt</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Kontakt</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="mailto:hellostranger@digil.me">hellostranger(at)digil.me</a>
                            </li>
                            <li class="">
                                Fremder, Freund oder digitaler Flaneur? Sag Hallo und lass uns das Eis brechen.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Lösoungen</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Lösoungen</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="https://www.starthere.at">Starthere</a>
                            </li>
                            <li class="">
                                <a href="https://www.22roachgang.com">22 Roach Gang</a>
                            </li>
                            <li class="">
                                <a href="https://www.openinnovationcircle.com">Openinnovationcircle</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Support</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Inhalte</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="https://starthere.at/howtonft">How to NFT</a>
                            </li>
                            <li class="">
                                <a href="https://firebasestorage.googleapis.com/v0/b/starthere-a97a6.appspot.com/o/cloud%2Fdigil.me%252Fassets%2Ffiles%2F3527_2880_NextBigIdea.pdf?alt=media&token=8681eaba-76f6-4cbc-8d6f-808d5b9f5a4f">Ideation presentation (pdf)</a>
                            </li>
                            <li class="">
                                <a href="https://firebasestorage.googleapis.com/v0/b/starthere-a97a6.appspot.com/o/cloud%2Fdigil.me%252Fassets%2Ffiles%2F3161_Methodenhappen.pdf?alt=media&token=9a12cb07-84d9-4854-9dd3-31d4eb910c2d">Ideengenerierung im Alltag (pdf)</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Eigener kollaborativer Digitalraum?</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Eigener kollaborativer Digitalraum?</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a (click)="openStarthereCollection('cgLhkPYThik7InM2K4n4')">Team</a>
                            </li>
                            <li class="buttons">
                                <a (click)="openStarthereCollection('cgLhkPYThik7InM2K4n4')">Projekt</a>
                            </li>
                            <li class="buttons">
                                <a (click)="openStarthereCollection('GoeOLJ9SPToLahBWIwkg')">Workshop</a>
                            </li>
                            <li class="buttons">
                                <a (click)="openStarthereCollection('fZIOernskV9y2dvLMDDt')">Startup</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
