import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-trainer',
  templateUrl: './gym-trainer.component.html',
  styleUrls: ['./gym-trainer.component.scss']
})
export class GymTrainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  users = [
    { 
      img:"assets/images/app_landing2/team/1.png",
      name:"Kolan Motto",
      designation:"Seniour UI/Xi Designer",
    },
    { 
      img:"assets/images/app_landing2/team/2.png",
      name:"Kolan Motto",
      designation:"Seniour UI/Xi Designer",
    },
    { 
      img:"assets/images/app_landing2/team/3.png",
      name:"Kolan Motto",
      designation:"Seniour UI/Xi Designer",
    },
    { 
      img:"assets/images/app_landing2/team/4.png",
      name:"Kolan Motto",
      designation:"Seniour UI/Xi Designer",
    }
  ]

  

}
