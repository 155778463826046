<!-- header Start-->
<section class="gym header" id="home">
    <div class="music-content">
        <div class="music-bg bg bg-shadow-top" [ngStyle]="{'background-image': 'url(assets/images/gym/header8.jpg)'}">

            <div class="container">
                <div class="row">
                    <div class="col-xl-10 col-md-10 offset-md-1">
                        <div class="center-text">
                            <div class="text-center">
                                <div class="header-text">
                                    <h1><span class="text-image2">Animate ideas.</span></h1>
                                </div>
                                <div class="header-sub-text">
                                    <h1><span class="bold-text text-image2">Go agile. Be Digital.</span></h1>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           <!--  <div class="text-center w-100" aTilt data-tilt data-tilt-max="3" data-tilt-speed="400"
                data-tilt-perspective="400">
                <div class="img-height">
                    <img src="assets/images/gym/cut_the_crap.png" alt="" class="img-fluid">
                </div>
            </div> -->
        </div>
    </div>
    
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
            <ng-container *ngFor="let header of headercarousel">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg" [ngStyle]="{'background-image': 'url(assets/images/gym/black_bg.jpg)'}">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span class="text-image">{{header.tagLine1}}</span></h1>
                                            </div>
                                            <div class="header-sub-text">
                                                <h1><span class="bold-text box">{{header.description}}</span></h1>
                                            </div>
                                            <!-- <div class="link-horizontal">
                                            <ul class="justify-content-center">
                                                <li>
                                                    <a class=" btn btn-default"><span>register 20%<sup>off</sup></span></a>
                                                </li>
                                                <li>
                                                    <a class=" btn btn-default">start now</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- header end-->