import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import SwiperCore, { SwiperOptions, EffectCards } from 'swiper';
SwiperCore.use([EffectCards]);

@Component({
    selector: 'app-gym-schedule',
    templateUrl: './gym-schedule.component.html',
    styleUrls: ['./gym-schedule.component.scss']
})
export class GymScheduleComponent implements OnInit {

    public index: any;
    activeIndex = 0;
    currentSlide: any;


    constructor(public cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.currentSlide = this.slides[2]
    }

    public config: SwiperOptions = {
        effect: 'coverflow',
        loop: true,
        centeredSlides: true,
        slidesPerView: 4,
        initialSlide: 0,
        keyboard: true,
        mousewheel: false,
        preventClicks: false,
        preventClicksPropagation: false,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1.43,
            slideShadows: false
        },
        breakpoints: {
            1199: {
                slidesPerView: 4,
                spaceBetween: 20
            },
            991: {
                slidesPerView: 4,
                spaceBetween: 25
            },
            767: {
                slidesPerView: 3,
                spaceBetween: 25
            },
            575: {
                slidesPerView: 2,
                spaceBetween: 25
            }
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination'
        }
    };

    updateActiveIndex(slider) {
        this.currentSlide = this.slides[slider[0].realIndex];
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
        //this.activeIndex = this.mySwiper.activeIndex;
    }

    public slides = [
        {
            img: 'assets/images/gym/portfolio/1.png',
            url: 'www.starthere.at',
            description: 'Die all-in-one one-click Kollaborationsplattform',
            tags: ['Tag1', 'Tag2'],
            name: "Starthere"
        },
        {
            img: 'assets/images/gym/portfolio/2.png',
            url: 'www.22roachgang.com',
            description: 'Ein NFT-Projekt mit integriertem Spiel. Werde zum Gang-Boss!',
            tags: ['Tag1', 'Tag2'],
            name: "22 Roach Gang"
        },
        {
            img: 'assets/images/gym/portfolio/3.png',
            url: 'www.openinnovationcircle.com',
            description: 'Einfach mit Anderen lernen. Gemeinsame und Kollaborative Lernreisen.',
            tags: ['Tag1', 'Tag2'],
            name: "Open innovation circle"
        },
        {
            img: 'assets/images/gym/portfolio/4.png',
            url: 'www.partyrazzi.photos',
            description: 'Deine Party- und Hochzeitsapp mit vielen Funktionen.',
            tags: ['Tag1', 'Tag2'],
            name: "Partyrazzi"
        },
        {
            img: 'assets/images/gym/portfolio/5.png',
            url: 'www.edugle.de',
            description: 'Die Suchmaschine für Bildungsinhalte.',
            tags: ['Tag1', 'Tag2'],
            name: "Edugle"
        },
        {
            img: 'assets/images/gym/portfolio/7.png',
            url: 'www.trustoer.com',
            description: 'Deine fälschungssicheres OER. Damit du immer weißt ob deine Inhalte verfälscht wurden.',
            tags: ['Tag1', 'Tag2'],
            name: "TrustOER"
        },
        {
            img: 'assets/images/gym/portfolio/6.png',
            url: 'www.simple-boards.com',
            description: 'Das einfachste Kanban-Board der Welt.',
            tags: ['Tag1', 'Tag2'],
            name: "Simple Boards"
        },
        {
            img: 'assets/images/gym/portfolio/8.png',
            url: 'www.ducleos.com',
            description: 'Produktverifizierung auf der Blockchain. Dein digitaler Twin für Produkte.',
            tags: ['Tag1', 'Tag2'],
            name: "Ducleos"
        },
    ]

    openURL(){
        console.log()
        window.open('https://'+this.currentSlide.url, '_blank')
    }
}
