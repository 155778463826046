<!-- about section Start-->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/gym/ruwen_trans.png" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>Was wir machen</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text text-image ">Digitale Innovationsfabrik</h3>
                        </div>
                        <div class="format-sub-text">
                            <div class="sub-heading">
                                <h4>Wo Ideen handgemacht werden</h4>
                            </div>
                            <p class="p-light about-para">In unserer digitalen Innovationsfabrik dreht sich alles um die Verwandlung von kühnen Ideen in bahnbrechende digitale Lösungen. Mit einem Team, das vor Kreativität sprudelt, und Technologien, die stets am Limit arbeiten, bauen wir Brücken von der Fantasie zur Realität. Wir sind mehr als nur Entwickler; wir sind Träumer, Macher und vor allem Innovatoren, die verstehen, dass hinter jedem großen digitalen Produkt eine noch größere Idee steckt. Tauche ein in eine Welt, wo Innovation nicht nur möglich, sondern alltäglich ist.</p>
                        </div>
<!--                         <a href="javascript:void(0)" class="btn btn-default primary-btn">Lust auf mehr?</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->