<!--brand slider start-->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-6 col-sm-6 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white text-image">Digital-Dojo: Meistere Deine digitale Transformation mit uns</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings text-image">Digital-Dojo: Meistere Deine digitale Transformation mit uns
                    </h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons" *ngFor="let service of services"
                                (mouseover)="hoveredService = service.description" (mouseleave)="hoveredService = ''">
                                <a href="#">{{ service.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 set-last margin-top">
                <div *ngIf="hoveredService" class="service-description text-image">
                    {{ hoveredService }}
                </div>
            </div>
        </div>
    </div>
</footer>


<section class="gym brand-slider">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="col-md-10 offset-md-1">
                    <div class="title title2">
                        <h6 class="font-primary borders main-text"><span>Die Wegbereiter</span></h6>
                        <div class="sub-title">
                            <h2 class="title-text text-capitalize text-center text-image2 ">Gemeinsam durchs digitale
                                Dickicht</h2>
                        </div>
                    </div>
                </div>
                <owl-carousel-o [options]="brandcarouselOptions">
                    <ng-container *ngFor="let brand of brands">
                        <ng-template carouselSlide class="item">
                            <a (click)="openURL(brand)">
                                <img [src]="brand.img" alt="" class="img-fluid">
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--brand slider end-->