import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-counter',
  templateUrl: './gym-counter.component.html',
  styleUrls: ['./gym-counter.component.scss']
})
export class GymCounterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  counter = [
    {
      count:'82',
      img:'assets/images/gym/counter/happy-icon.png',
      type:'Prozent aller Unternehmen nutzen noch das Fax - Ist wie Kutsche fahren auf der Autobahn!'
    },
    {
      count:'1899',
      img:'assets/images/gym/counter/project-icon.png',
      type:'wollte Charles Duell (Patentamtchef USA) alle Patentämter schließen - wurde ja eh schon alles erfunden.'
    },
    {
      count:'50',
      img:'assets/images/gym/counter/gramm.png',
      type:'Gramm wiegt das gesamte Wissen, dass derzeit im Internet vorhanden ist. Ohne Hardware.'
    },
    {
      count:'1,9',
      img:'assets/images/gym/counter/award-icon.png',
      type:'Punkte verliert der Digitalisierungsindex im letzten Jahr laut bmwk - Wussten Sie nicht? Wir auch nicht!'
    }]

}
