import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-testimonial',
  templateUrl: './gym-testimonial.component.html',
  styleUrls: ['./gym-testimonial.component.scss']
})
export class GymTestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  users = [
    { 
      quote:'Wenn Sie einen scheiss Prozess digitalisieren, haben Sie einen scheiss digitalen Prozess.',
      name: 'Thorsten Dirks',
      title: 'Deutscher Manager',
      img:"assets/images/gym/testimonial/quote.png"
    },
    {
      quote:'Wir haben uns komplett verlaufen, kommen aber gut voran.',
      name: 'Anonym',
      title: '-',
      img:"assets/images/gym/testimonial/quote.png"
    },
    {
      quote:'Der Mensch will immer, dass alles anders wird und gleichzeitig will er, dass alles beim Alten bleibt.',
      name: 'Paulo Coelho',
      title: 'Brasilianischer Schriftsteller',
      img:"assets/images/gym/testimonial/quote.png"
    },
    {
      quote:'Das Internet ist Neuland.',
      name: 'Angela Merkel',
      title: 'ehem. Bundeskanzlerin',
      img:"assets/images/gym/testimonial/quote.png"
    },
    {
      quote:'Wenn du etwas machst, wie du es seit zehn Jahren gemacht hast, dann sind die Chancen groß, dass du es falsch machst.',
      name: 'Charles Kettering',
      title: 'US Wissenschaftler',
      img:"assets/images/gym/testimonial/quote.png"
    },
    {
      quote:'KI ist wahrscheinlich das Beste oder das Schlimmste, was der Menscheit passieren kann.',
      name: 'Stephen Hawking',
      title: 'Astrophysiker',
      img:"assets/images/gym/testimonial/quote.png"
    }
  ]
  
  testimonialCarouselOptions= {
    items: 1,
    margin: 0,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }



}
